import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  baseUrl: string = 'https://lawyers-dev-api.thelawyerss.com/api/v1';

  constructor(protected http: HttpClient) { }

  get<T>(endpoint: string, params?: any, url?: string): Observable<T> {
    const apiUrl = url ?? this.baseUrl + "/" +endpoint;
    return this.http.get<T>(apiUrl, { params });
  }

  post<T>(endpoint: string, body: any, headers?: HttpHeaders, url?: string): Observable<T> {
    const apiUrl = url ?? this.baseUrl + "/" +endpoint;
    return this.http.post<T>(apiUrl, body, { headers });
  }

  put<T>(endpoint: string, body: any, headers?: HttpHeaders, url?: string): Observable<T> {
    const apiUrl = url ?? this.baseUrl + "/" +endpoint;
    return this.http.put<T>(apiUrl, body, { headers });
  }

  delete<T>(endpoint: string, body?: any, url?: string): Observable<T> {
    const apiUrl = url ?? this.baseUrl + "/" +endpoint;
    return this.http.delete<T>(apiUrl, { body: body });
  }

  
}
